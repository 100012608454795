export interface IPlanAssociation {
	plan: {
		name: string
		planType: string
	}
	payer: {
		id: string
		name: string
		matchingPlans?: {
			name: string
			uniqueId: string
			id: string
		}[]
	}
	associationId: string
	status: IPlanAssociationStatus
	requestId: string
	dateAdded: string
	isSelected?: boolean
}

export interface IPublishedPlanAssociation {
	id: string
	name: string
	planType: {
		id: string
		name?: string
	}
	payer: {
		id: string
		name?: string
	}
}

export enum IPlanAssociationStatus {
	READY = 'READY',
	DELETED = 'DELETED',
	PUBLISHED = 'PUBLISHED',
}
