
import { defineComponent, SetupContext } from 'vue'

export default defineComponent({
	name: 'delete-button',
	props: {
		canDelete: {
			type: Boolean,
			default: false,
		},
		isDeleting: {
			type: Boolean,
			default: false,
		},
	},
	setup(_, context: SetupContext) {
		const onDelete = () => {
			context.emit('deleteItems')
		}
		return {
			onDelete,
		}
	},
})
