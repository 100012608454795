
import { defineComponent, onMounted, ref, watchEffect, computed } from 'vue'
import { usePermission } from '@/utils/composables'

import {
	IPlanAssociation,
	IPlanAssociationStatus,
} from '@server/api/models/fe/planAssociation'
import { IPayerPlan } from '@server/api/models/fe/payer'

import {
	fetchPlanAssociationQueueList,
	updatePlanAssociationQueueItems,
} from '@/service/planAssociation.http'
import { fetchPlans } from '@/service/shared.http'

import DeleteButton from '@/components/worklist/controls/delete-button.vue'
import { MyndshftPanel } from '@myndshft/panel'
import PublishButton from '@/components/worklist/controls/publish-button.vue'

export default defineComponent({
	name: 'payer-plan-queue',
	components: {
		[MyndshftPanel.name]: MyndshftPanel,
		[DeleteButton.name]: DeleteButton,
		[PublishButton.name]: PublishButton,
	},
	setup() {
		const permission = usePermission()

		const headers = ref([
			{
				value: 'payer.name',
				text: 'Payer',
				width: '400px',
			},
			{
				value: 'plan.name',
				text: 'Plan Name',
			},
			{
				value: 'plan.planType.name',
				text: 'Plan Type',
				width: '300px',
			},
			{
				text: '',
				value: 'data-table-select',
				filterable: false,
				sortable: false,
			},
		])

		const isLoading = ref(false)
		const isLoadingPlans = ref(true)
		const isDeleting = ref(false)
		const isPublishing = ref(false)

		const data = ref<IPlanAssociation[]>([])
		const availablePlans = ref<IPayerPlan[]>([])
		const search = ref('')

		const onItemSelect = (item: any) => {
			item.isSelected = !item.isSelected
			data.value = [...data.value]
		}

		const onSelectAll = ({
			items,
			value,
		}: {
			items: any[]
			value: boolean
		}) => {
			items.forEach(item => {
				item.isSelected = value
			})
			data.value = [...data.value]
		}

		const save = (item: any) => {
			if (item?.plan?.planType?.name) {
				updatePlanAssociationQueueItems([item], 'plan_type')
			}
		}

		const canEdit = computed(() => {
			const requestedItems = data.value.filter(item => item.isSelected)
			return !!(requestedItems.length && permission.$canEditPayer())
		})

		const onDeleteItems = () => {
			isDeleting.value = true
			const requestedItems = data.value
				.filter(item => item.isSelected)
				.map(item => {
					item.status = IPlanAssociationStatus.DELETED
					return item
				})
			updatePlanAssociationQueueItems(requestedItems, 'status').then(() => {
				data.value = data.value.filter(
					item => item.status === IPlanAssociationStatus.READY
				)
				isDeleting.value = false
			})
		}

		const onPublish = () => {
			isPublishing.value = true
			const requestedItems = data.value
				.filter(item => item.isSelected)
				.map(item => {
					item.status = IPlanAssociationStatus.PUBLISHED
					return item
				})
			updatePlanAssociationQueueItems(requestedItems, 'status').then(() => {
				data.value = data.value.filter(
					item => item.status === IPlanAssociationStatus.READY
				)
				isPublishing.value = false
			})
		}

		const convertInput = (event: any) => ({
			name: event.name,
			id: event.id,
			unique_name: event.unique_name,
		})

		onMounted(() => {
			isLoading.value = true
			data.value = []
			availablePlans.value = []
			fetchPlanAssociationQueueList('READY').then((results: any) => {
				data.value = results
				isLoading.value = false
			})
			fetchPlans().then(plansList => {
				availablePlans.value = plansList
				isLoadingPlans.value = false
			})
		})

		watchEffect(() => {
			fetchPlans().then(plansList => {
				availablePlans.value = plansList
				isLoadingPlans.value = false
			})
		})

		return {
			headers,
			data,
			isLoading,
			availablePlans,
			isLoadingPlans,
			isDeleting,
			canEdit,
			isPublishing,
			search,
			open,
			save,
			onPublish,
			onDeleteItems,
			onItemSelect,
			onSelectAll,
			convertInput,
		}
	},
})
