
import { defineComponent, ref, watchEffect } from 'vue'
import { useRouter } from '@/utils/composables'

import PayerList from '@/components/payer/payer-list.vue'
import PayerPlanQueue from '@/components/payer/payer-plan-queue.vue'

export default defineComponent({
	components: {
		[PayerList.name]: PayerList,
		[PayerPlanQueue.name]: PayerPlanQueue,
	},
	setup() {
		const router = useRouter()

		const limit = ref(50)
		const page = ref(1)
		const query = ref('')

		watchEffect(() => {
			const queryString = router.app.$route.query

			limit.value = Number(queryString.limit) || 50
			page.value = Number(queryString.page) || 1
			query.value = (queryString.query as string) || ''

			window.scrollTo(0, 0)
		})

		return {
			limit,
			page,
			query,
		}
	},
})
