
import { defineComponent, ref, SetupContext } from 'vue'
import { debounce } from '@/utils/helpers'

export default defineComponent({
	name: 'myndshft-search',
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		initWith: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		disabledTooltip: {
			type: String,
			default: 'This control is disabled',
		},
	},
	setup(props, context: SetupContext) {
		const query = ref(props.initWith)

		const search = () => {
			context.emit('search', query.value)
		}

		const debouncedSearch = debounce(search, 500)

		return {
			query,
			search,
			debouncedSearch,
		}
	},
})
