import axios from 'axios'
import queryString from 'query-string'

import interceptor from '@/service/interceptor'

import { getBff } from './shared.http'
import {
	IPlanAssociation,
	IPublishedPlanAssociation,
} from './../../server/api/models/fe/planAssociation'

axios.interceptors.request.use(interceptor as any)

export async function fetchPlanAssociationQueueList(
	status: string
): Promise<IPlanAssociation[]> {
	const qs = queryString.stringify({
		status,
	})

	const path = `${getBff()}/planAssociations?${qs}`
	const response = await axios.get<any>(path)
	const payers = response.data ?? []
	return payers
}

export async function updatePlanAssociationQueueItems(
	associations: IPlanAssociation[],
	type: string
) {
	const path = `${getBff()}/planAssociations`
	const body = {
		associations,
		type,
	}
	const response = await axios.patch<any>(path, body)
	return response.data
}

export async function fetchPayerPlanAssociations(
	uuid: string
): Promise<IPublishedPlanAssociation[]> {
	const path = `${getBff()}/payers/${uuid}/plans`

	const response = await axios.get<any>(path)

	return response.data
}

export async function updatePublishedPayerPlanAssociation(
	payerId: string,
	plan: any
): Promise<void> {
	const path = `${getBff()}/payers/${payerId}/plans`
	const body = {
		plan,
	}
	const response = await axios.patch<any>(path, body)
	return response.data
}
