
import { defineComponent, onMounted, ref, watchEffect } from 'vue'
import queryString from 'query-string'
import { useRouter } from '@/utils/composables'

import { MyndshftPanel } from '@myndshft/panel'
import MyndshftTable from '@/components/table/table.vue'
import MyndshftTableCell from '@/components/table/cell.vue'
import MyndshftPagination from '@/components/table/pagination.vue'
import Search from '@/components/search/search.vue'
import V2Cta from '@/components/shared/v2-cta.vue'

import { fetchPayerList } from '@/service/payer.http'
import { ActiveStatus } from '@myndshft/types-mc-api'
import { IPayerListItem } from '@server/api/models/fe/payer'

export default defineComponent({
	name: 'payer-list',
	components: {
		[MyndshftTable.name]: MyndshftTable,
		[MyndshftTableCell.name]: MyndshftTableCell,
		[MyndshftPagination.name]: MyndshftPagination,
		[MyndshftPanel.name]: MyndshftPanel,
		[Search.name]: Search,
		[V2Cta.name]: V2Cta,
	},
	props: {
		limit: {
			type: Number,
			default: 50,
		},
		page: {
			type: Number,
			default: 1,
		},
		query: {
			type: String,
			default: '',
		},
	},
	setup(props) {
		const router = useRouter()

		const totalPages = ref(0)
		const totalCount = ref(0)
		const currentCount = ref(0)
		const isLoading = ref(false)
		const data = ref<IPayerListItem[]>([])
		const columns = [
			{
				field: 'name',
				label: 'Payer Name',
			},
			{
				field: 'status',
				label: 'Status',
				maxWidth: '100px',
			},
		]

		onMounted(() => {
			isLoading.value = true
			fetchPayerList(props.limit, props.page, props.query).then(
				(results: any) => {
					data.value = results.payers
					totalPages.value = results.totalPages

					isLoading.value = false
					setCounts(
						props.page,
						props.limit,
						results.total,
						results.payers.length
					)
				}
			)
		})

		watchEffect(() => {
			fetchPayerList(props.limit, props.page, props.query).then(
				(results: any) => {
					data.value = results.payers
					totalPages.value = results.totalPages

					isLoading.value = false
					setCounts(
						props.page,
						props.limit,
						results.total,
						results.payers.length
					)
				}
			)
		})

		const filterSearch = (query: string) => {
			if (query !== props.query) {
				isLoading.value = true

				const qs = queryString.stringify({
					limit: props.limit,
					page: 1,
					query: query ? query : undefined,
				})
				router.push(`/payer/list?${qs}`)
			}
		}

		const urlTemplate = (index: number) => {
			const qs = queryString.stringify({
				limit: props.limit,
				page: index,
				query: props.query,
			})
			return `/payer/list?${qs}`
		}

		const setCounts = (
			page: number,
			limit: number,
			total: number,
			resultCount: number
		) => {
			currentCount.value = page * limit - limit + resultCount
			totalCount.value = total
		}

		return {
			columns,
			data,
			totalPages,
			urlTemplate,
			filterSearch,
			isLoading,
			currentCount,
			totalCount,
			ActiveStatus,
		}
	},
})
